import { Dispatch, SetStateAction, useMemo } from "react"
import { TViewResetFlag } from "../JsTpviewer"
import { TViewValue } from "../types"

export type TViewControlsProps = {
    currentView:TViewValue
    setCurrentView: Dispatch< SetStateAction< TViewValue >>
    setViewClicked: Dispatch< SetStateAction< boolean    >>
    isNeedToResetViewRef: React.MutableRefObject<TViewResetFlag>
    viewControl: 'main' |  'before|after'
}

const ViewControls = (props: TViewControlsProps) =>{
    const { currentView, setCurrentView, setViewClicked, isNeedToResetViewRef, viewControl } = props

    const viewButtons = useMemo(()=>{
        const views:TViewValue[] = ['front','top','bottom','left','right']
        
        return(
            views.map((viewItem, viewIndex)=>{
                return(
                    <button
                        key={`view-control-button-${viewIndex}`}
                        className= {currentView !== viewItem ? 'view-button' : 'view-button active' }
                        onClick = { ()=>{
                            setCurrentView(viewItem)
                            setViewClicked(true)
                            if(viewControl === "main"){
                                isNeedToResetViewRef.current['main'] = true
                            }else{
                                isNeedToResetViewRef.current['before'] = true
                                isNeedToResetViewRef.current['after'] = true
                            }
                        }}
                    >
                        { viewItem }
                    </button>
                )
            })
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentView])



    return(
        <div id = 'view-controls'>
            <div className='view-from'>
                View
            </div>
            
            <div className='buttons-wrapper'>
                { viewButtons }
            </div>
        </div>  
    )
}

export default ViewControls