import JSZip from "jszip"
import { Dispatch, SetStateAction } from "react"
import { TApplicationMode } from "../JsTpviewer"
import { TTeethStepsPosition } from "../ModelLoader/ModelLoader"
import unzipSmilewrapper from "../ModelLoader/unzipSmilewrapper"
import unzipObj          from "../ModelLoader/unzipObj"
import { TSteppedModelData, TTeethModelData } from "../types"
import detectZipFormat from "../ModelLoader/detectZipFormat"

type TZipFileLoaderProps = {
    setApplicationMode              : (value: React.SetStateAction<TApplicationMode>) => void
    setGingivaModeGeometry          : Dispatch<SetStateAction<TSteppedModelData>>
    setPreloaderVisible             : Dispatch<SetStateAction<boolean>>
    setTeethModelData               : Dispatch<SetStateAction<TTeethModelData>>
    setTeethModelStepTransformation : Dispatch<SetStateAction<TTeethStepsPosition[][]>>
    setSmilewrapperInfo             : Dispatch<SetStateAction<string | undefined>>
    onGlobalError                   : (errorString:string) => void
}

const ZipFileLoader = (props:TZipFileLoaderProps) => {
    const {
        setApplicationMode,
        setGingivaModeGeometry,
        setPreloaderVisible,
        setSmilewrapperInfo,
        setTeethModelData,
        setTeethModelStepTransformation,
        onGlobalError
    } = props
    return(
        <div id='file-loader'>
            <label className="button-load-file">
                Load 
                <input 
                    type="file"
                    accept="application/zip"
                    style={{display: 'none'}} 
                    required
                    onChange={(e)=>{
                        
                        if(e.target.files && e.target.files[0]){
                            setApplicationMode('CASE_FROM_LOCAL_FILE_PARSING')

                            const fileData:File = e.target.files[0]
                            const zip = new JSZip()
                            zip.loadAsync(fileData)
                            .then((fileContent:JSZip) => {
                                
                                const zipFormat = detectZipFormat(fileContent)
                                
                                switch(zipFormat){
                                    
                                    case 'obj': {
                                        unzipObj(
                                            fileContent,
                                            {
                                                url: '',
                                                setGingivaModelData: setGingivaModeGeometry,
                                                setPreloaderVisible,
                                                setTeethModelData,
                                                setTeethModelStepTransformation,
                                                setSmilewrapperInfo,
                                                onGlobalError
                                            },
                                            ()=>{
                                                setApplicationMode('CASE_FROM_LOCAL_FILE_DONE')
                                            }
                                        )
                                    } break

                                    case 'smilewrapper/ctm' :{
                                        unzipSmilewrapper(
                                            fileContent,
                                            {
                                                url: '',
                                                setGingivaModelData: setGingivaModeGeometry,
                                                setPreloaderVisible,
                                                setTeethModelData,
                                                setTeethModelStepTransformation,
                                                setSmilewrapperInfo,
                                                onGlobalError
                                            },
                                            ()=>{
                                                setApplicationMode('CASE_FROM_LOCAL_FILE_DONE')
                                            }
                                        )
                                    } break

                                    default:{
                                        onGlobalError("Zip file have unknown format")
                                        console.error("Zip file have unknown format")
                                    }break
                                }

                                
                            })
                            .catch((e)=>{
                                onGlobalError("Can't unzip local file")
                                console.error("Can't unzip local file")
                            })
                        }
                    }}
                />
            </label>
        </div>
    )
}

export default ZipFileLoader
