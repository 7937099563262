import { useEffect, Dispatch, SetStateAction } from "react"
import * as THREE from 'three'
import { TSteppedModelData, TTeethModelData } from '../types'
import getSmilewrapperModelsData from './getSmilewrapperModelsData'
import getObjModelsData          from './getObjModelsData'
import getMultiZipObjModelsData from "./getMultiZipObjModelData"
import { TApplicationMode } from "../JsTpviewer"

type TModelLoaderProps = {
    url: string[]
    setTeethModelData               : Dispatch< SetStateAction< TTeethModelData        >>
    setGingivaModelData             : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelGeometry           : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelStepTransformation : Dispatch< SetStateAction< TTeethStepsPosition[][]>>
    setPreloaderVisible             : Dispatch< SetStateAction< boolean                >>
    setSmilewrapperInfo             : Dispatch< SetStateAction< string | undefined     >>
    setApplicationMode              : Dispatch<React.SetStateAction<TApplicationMode>>
    onGlobalError                   : (errorString:string) => void
}

export type TTeethStepsPosition = {
    id: string
    position: THREE.Vector3
    rotationMatrix: THREE.Matrix3
}


const ModelLoader = (props: TModelLoaderProps) => {
    const {
        url,
        setTeethModelData,
        setGingivaModelData,
        setTeethModelStepTransformation,
        setPreloaderVisible,
        setTeethModelGeometry,
        setSmilewrapperInfo,
        onGlobalError,
        setApplicationMode,
    } = props

    useEffect(()=>{

        if( url.length === 1 ){
            // ZIP with smilewrapper format inside
            if(url[0].indexOf('.zip')>-1){

                getSmilewrapperModelsData({
                    url:url[0],
                    setTeethModelData,
                    setGingivaModelData,
                    setTeethModelStepTransformation,
                    setPreloaderVisible,
                    setSmilewrapperInfo,
                    onGlobalError
                })
            }

            // CASE WITH JSON AND OBJ-FILES BESIDE
            if(url[0].indexOf('.json')>-1){

                getObjModelsData({
                    url:url[0],
                    setGingivaModelData,
                    setPreloaderVisible,
                    setTeethModelGeometry
                })

            }
        } else if(url.length > 1){
            getMultiZipObjModelsData({
                urlArray:url,
                setGingivaModelData,
                setPreloaderVisible,
                setTeethModelGeometry,
                onGlobalError,
                onFinish: ()=>{ setApplicationMode('CASE_FROM_URL_DONE') }
            })
        }
    // eslint-disable-next-line
    },[url])

    return(
        <>
        </>
    )
}

export default ModelLoader
