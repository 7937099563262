import React, {useEffect, useState} from "react"
import WasmTpviewer from "./WasmTpviewer"
import JsTpviewer from "./JsTpviewer"
import GlobalError from "./Ui/GlobalError"

type TpviewerImplementation = 'js' | 'wasm'

const getImplSearchParam = (): TpviewerImplementation | undefined => {
    const impl = new URL(window.location.href).searchParams.get('impl')
    if (impl === 'js' || impl === 'wasm') {
        return impl as TpviewerImplementation
    }
    return undefined
}

const TpviewerSwitcher = () => {
    const [tpviewerImplementation, setTpviewerImplementation] = useState<TpviewerImplementation>(getImplSearchParam() || 'wasm')

    const [ errorGlobalMessage, setErrorGlobalMessage ] = useState<string>('')

    const onWasmCrash = () => {
        console.warn('Switching to js implementation')
        setTpviewerImplementation('js')
    }



    const onGlobalError = (errorString:string)=>{ 
        setErrorGlobalMessage(errorString) 
    }

    useEffect(() => {
        (window as any).onGlobalError = onGlobalError
    },[])

    return(
        <>
            {
                tpviewerImplementation === 'wasm' && 
                    <WasmTpviewer 
                        onCrash       = { onWasmCrash   }
                        onGlobalError = { onGlobalError }
                    />
            }

            {
                tpviewerImplementation === 'js' &&
                    <JsTpviewer
                        onGlobalError = { onGlobalError }
                    />
            }
            
            {
                Boolean(errorGlobalMessage)&&
                    <GlobalError/>
            }
        </>
    ) 
}

export default TpviewerSwitcher
