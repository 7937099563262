import { useFrame, useThree } from "@react-three/fiber"
import * as THREE from 'three'
import { useEffect, useRef } from "react"
import { TViewValue } from "../types"
import getZoomValue from "../Utils/get-viewport-zoom-value"
import { TApplicationActiveTab, TViewResetFlag, TViewStateAccumulator } from "../JsTpviewer"

type TViewTransformatorProps = {
    activeTab?: TApplicationActiveTab  
    currentView: TViewValue
    isViewClicked: boolean
    setCameraParameters?: (x:number,y:number,z: number, zoom: number) => void
    viewStateAccumulatorRef: React.MutableRefObject<TViewStateAccumulator>
    isNeedToResetViewRef: React.MutableRefObject<TViewResetFlag>
    viewType: 'main' |  'before' | 'after'
}


const setViewVisibility = (currentView: TViewValue, sceneObjects: THREE.Object3D[]) => {
    
    sceneObjects.forEach( model => model.visible = true )

    switch(currentView){
        
        case "top":
            sceneObjects.forEach( (model) => {
                if(model.name.indexOf('lower')>-1){
                    model.visible = false
                }
                if(model.name.indexOf('-3')>-1 || model.name.indexOf('-4')>-1){
                    model.visible = false
                }
            } )

            break
        case "bottom":
            sceneObjects.forEach( (model) => {
                if(model.name.indexOf('upper')>-1){
                    model.visible = false
                }
                if(model.name.indexOf('-1')>-1 || model.name.indexOf('-2')>-1){
                    model.visible = false
                }
            } )
            break
        default:
            break
    }
}

const setModelsRotation = (currentView: TViewValue, sceneObjects: THREE.Object3D[]) =>{
    
    switch(currentView){
        case 'top':
            sceneObjects.forEach( (model) => {model.rotation.copy(new THREE.Euler(-Math.PI/2,0,0)) })
            break
        case 'bottom':
            sceneObjects.forEach( (model) => { model.rotation.copy(new THREE.Euler(Math.PI/2,0,0)) })
            break
        case 'left':
            sceneObjects.forEach( (model) => { model.rotation.copy(new THREE.Euler(0,-Math.PI/2,0)) })
            break
        case 'right':
            sceneObjects.forEach( (model) => { model.rotation.copy(new THREE.Euler(0,Math.PI/2,0))})
            break

        default:
            sceneObjects.forEach( model => model.rotation.copy(new THREE.Euler()))
            break
    }
}

export const cameraDistance = 500
const ViewTransformator = ( props: TViewTransformatorProps ) => {
    
    const { 
        currentView, 
        isViewClicked, 
        activeTab, 
        setCameraParameters,
        viewStateAccumulatorRef,
        isNeedToResetViewRef,
        viewType
    } = props
    
    const { camera, scene } = useThree(state => state)
    const { children } = scene
    
    useEffect(()=>{
        isNeedUpdate.current = true
    },[currentView])


    const isNeedUpdate = useRef(true)

    useFrame(({ gl, scene, camera }) => {
        if(isNeedUpdate.current === true){

            camera.zoom = getZoomValue(activeTab)
            isNeedUpdate.current = false
            camera.position.copy(new THREE.Vector3(0,0,cameraDistance))
            camera.setRotationFromEuler(new THREE.Euler(0,0,0))

            if( activeTab === 'BEFORE_AFTER'){

                if(viewStateAccumulatorRef.current && typeof(viewStateAccumulatorRef.current['before'])!=='undefined' ){

                    camera.position.copy(viewStateAccumulatorRef.current['before'].cameraPosition)
                    camera.setRotationFromEuler(viewStateAccumulatorRef.current['before'].cameraRotation)
                    viewStateAccumulatorRef.current['before'] = undefined

                }else if(viewStateAccumulatorRef.current && typeof(viewStateAccumulatorRef.current['after'])!=='undefined'){

                    camera.position.copy(viewStateAccumulatorRef.current['after'].cameraPosition)
                    camera.setRotationFromEuler(viewStateAccumulatorRef.current['after'].cameraRotation)
                    viewStateAccumulatorRef.current['after'] = undefined

                }else{
                    
                    camera.position.copy(new THREE.Vector3(0,0,cameraDistance))
                    camera.setRotationFromEuler(new THREE.Euler(0,0,0))
                }

            }else{
                // ACTIVE TAB === undefined ( TIMELINE MODE )
                if(viewStateAccumulatorRef.current && typeof(viewStateAccumulatorRef.current['main'])!=='undefined'){
                    
                    camera.position.copy(viewStateAccumulatorRef.current['main'].cameraPosition)
                    camera.setRotationFromEuler(viewStateAccumulatorRef.current['main'].cameraRotation)
                    viewStateAccumulatorRef.current['main'] = undefined
                }else{
                    
                    camera.position.copy(new THREE.Vector3(0,0,cameraDistance))
                    camera.setRotationFromEuler(new THREE.Euler(0,0,0))
                }
            }

            

            setViewVisibility(currentView, children)
            setModelsRotation(currentView, children)
            camera.updateProjectionMatrix()
            gl.render(scene, camera)
        }

        if(isNeedToResetViewRef && typeof(isNeedToResetViewRef.current)!=='undefined'){
            
            if(isNeedToResetViewRef.current[viewType] === true){
                isNeedToResetViewRef.current[viewType] = false
                camera.position.copy(new THREE.Vector3(0,0,cameraDistance))
                camera.setRotationFromEuler(new THREE.Euler(0,0,0))
            }
        }
    })
    

    return(<></>)
}

export default ViewTransformator
