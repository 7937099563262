import { useMemo, useState, useEffect, useRef } from 'react'
import * as THREE from 'three'
import ModelLoader, { TTeethStepsPosition } from "./ModelLoader/ModelLoader"
import { TModelData, TSteppedModelData, TTeethModelData, TViewValue } from './types'
import Preloader from './Ui/Preloader'
import { mergeBufferGeometries } from './ModelLoader/BufferGeometryUtils'
import getZoomValue from './Utils/get-viewport-zoom-value'
import useInterval from './Utils/use-interval'
import View3dTimeline from './Ui/View3dTimeline'
import View3dBeforeAfter from './Ui/View3dBeforeAfter'
import ZipFileLoader from './Ui/ZipFileLoader'
import ModelFromFromGeometry from './Scene/ModelFromFromGeometry'
import Helper from './Utils/Helper'
import getCtmTransformedGeometry from './ModelLoader/getCtmTransformedGeometry'

export const delayValue = 700

export type TViewState = {
    cameraPosition : THREE.Vector3
    cameraRotation : THREE.Euler
}

export type TViewStateAccumulator = {
    main  : TViewState | undefined
    before: TViewState | undefined
    after : TViewState | undefined
}

export type TViewResetFlag = {
    main  : boolean
    before: boolean
    after : boolean
}

export type TViewBeforeAfter = "" | "BEFORE" | "AFTER"

export type TApplicationMode = 'CASE_FROM_URL_LOADING' | 'CASE_FROM_URL_DONE' | 'CASE_FROM_LOCAL_FILE' | 'CASE_FROM_LOCAL_FILE_PARSING' | 'CASE_FROM_LOCAL_FILE_DONE' 
export type TApplicationActiveTab = 'TIMELINE' | 'BEFORE_AFTER'

type TJsTpviewerProps = {
    onGlobalError: (errorString:string) => void
}

const JsTpviewer = (props: TJsTpviewerProps) => {
    const { onGlobalError } = props
    const parsedUrl = new URL(window.location.href).searchParams.getAll('case')

    const url = parsedUrl !== null && parsedUrl.length > 0 ?  parsedUrl : []
    const [ activeTab, setActiveTab] = useState<TApplicationActiveTab>('TIMELINE')
    const prevTabRef = useRef<TApplicationActiveTab>('TIMELINE')
    const viewForAutoPlay:TViewValue[] = ['front', 'top']
    const [ viewIndex                    , setViewIndex                    ] = useState(0)
    const [ isPreloaderVisible           , setPreloaderVisible             ] = useState(true)
    const [ applicationMode              , setApplicationMode              ] = useState<TApplicationMode>( url.length>0 ? 'CASE_FROM_URL_LOADING' : 'CASE_FROM_LOCAL_FILE')
    const [ stepIndex                    , setStepIndex                    ] = useState(0)
    const [ isPlayed                     , setIsPlayed                     ] = useState(false) 
    const [ currentView                  , setCurrentView                  ] = useState<TViewValue>('front')

    const [ isViewClicked                , setViewClicked                  ] = useState(false) // disable autochange of view  if user change view

    const [ teethModelStepTransformation , setTeethModelStepTransformation ] = useState<TTeethStepsPosition[][]>([])
    const [ delay, setDelay] = useState<null|number>(null) // interval size

    const [                              , setMouseMove                    ] = useState(false)
    
    const viewStateAccumulatorRef   = useRef<TViewStateAccumulator>({
        main: undefined,
        after: undefined,
        before: undefined,
    })
    const isNeedToResetViewRef = useRef<TViewResetFlag>({
        main: false,
        after: false,
        before: false,
    })
    const cameraPosition = useRef({
        position: new THREE.Vector3(0,0,100),
        zoom: getZoomValue(),
    })



    const setCameraParameters = (x:number, y:number, z:number, zoom: number) =>{
        cameraPosition.current = {
            position: new THREE.Vector3(x,y,z),
            zoom
        }
    }
    const [ pauseDelay, setPauseDelay] = useState<null|number>(null) // interval size
    // teeths have base geometry for all steps, but transformed for every step
    const [ teethModelData, setTeethModelData ] = useState<TTeethModelData>({
        upper: [],
        lower: [],
    })

    const [ teethModelGeometry, setTeethModelGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    // gingiva have own geometry for every step
    const [ gingivaModelGeometry, setGingivaModeGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    const [smilewrapperInfo, setSmilewrapperInfo         ] = useState<string | undefined>()


    useEffect(()=>{
        const teethsteppedGeometry:TSteppedModelData = {
            upperSteps: [],
            lowerSteps: []
        }

        // CTM SCENARIO : Base geometry + few transformations matrixes
        if(teethModelStepTransformation.length > 0){
            
            teethModelStepTransformation.forEach((stepTransformation, stepIndex)=>{
                
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${stepIndex}-upper`,
                    data: getCtmTransformedGeometry( stepTransformation,teethModelData.upper )
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${stepIndex}-lower`,
                    data: getCtmTransformedGeometry( stepTransformation,teethModelData.lower )
                })

            })

            setTeethModelGeometry(teethsteppedGeometry)
            
        }else if(teethModelStepTransformation.length === 0 && ( teethModelData.lower.length > 0 || teethModelData.upper.length >0 )){
            for(let i = 0; i<teethModelData.lower.length; i++){
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${i}-upper`,
                    data: teethModelData.upper[i].data
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${stepIndex}-lower`,
                    data: teethModelData.lower[i].data
                })
            }
            setTeethModelGeometry(teethsteppedGeometry)
        } 
        
        return(()=>{
            teethsteppedGeometry.lowerSteps = []
            teethsteppedGeometry.upperSteps = []
        })
    },[teethModelData, teethModelStepTransformation])
    

    // ANIMATION START
    useInterval(()=>{
        if(isPlayed === true && stepIndex + 1 < gingivaModelGeometry.upperSteps.length){
            setStepIndex (stepIndex + 1)
        }else{
            if(viewIndex>-1 && isPlayed === true && stepIndex === gingivaModelGeometry.upperSteps.length-1 ){
                if(viewIndex < viewForAutoPlay.length-1){
                    setPauseDelay(2000)
                    setDelay(null)
                }else{
                    setDelay(null)
                    setIsPlayed(false)
                }
            }else{
                setDelay(null)
                setIsPlayed(false)
            }
        }
    }, delay)

    useInterval(()=>{
        setViewIndex(viewIndex+1)
        setPauseDelay(null)
    }, pauseDelay)

    useEffect(()=>{
        if(viewIndex>-1 && viewIndex <= viewForAutoPlay.length-1){
            if(isViewClicked === false){
                setCurrentView(viewForAutoPlay[viewIndex])
                setStepIndex(0)
                setDelay(delayValue)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[viewIndex, isViewClicked])
    // ANIMATION END

    const cancelStartupAnimation = () =>{
        setPauseDelay(null)
        setDelay(null)
        setIsPlayed(false)
        setViewIndex(-1)
    }


    
    const teethsModels = useMemo(()=>{ return( // FOR TIMELINE
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {stepIndex}
        />
    )},[teethModelGeometry, stepIndex])

    const teethsModelsBefore = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {0}
        />
    )},[teethModelGeometry])


    const teethsModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {teethModelGeometry.upperSteps.length-1 }
        />
    )},[teethModelGeometry])

    const gingivaModels = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {stepIndex }
        />
    )},[gingivaModelGeometry, stepIndex])


    const gingivaModelsBefore = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = { 0 }
        />
    )},[gingivaModelGeometry])

    const gingivaModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {gingivaModelGeometry.upperSteps.length-1 }
        />
    )},[gingivaModelGeometry])

    useEffect(()=>{

        if( isPreloaderVisible === false){
            setApplicationMode('CASE_FROM_URL_DONE')
        }
        if( applicationMode === 'CASE_FROM_LOCAL_FILE_DONE' || applicationMode === 'CASE_FROM_URL_DONE'){
            setTimeout(()=>{
                setDelay(delayValue)
                setIsPlayed(true)
            },1000)
        }
    },[applicationMode, isPreloaderVisible])

    // disable startup animation if before/after was enabled
    useEffect(()=>{
        if(isPlayed===true){
            cancelStartupAnimation()
        }
    // eslint-disable-next-line
    },[activeTab])

    return (
        <>
            
            {
                applicationMode === 'CASE_FROM_URL_LOADING' &&
                <>
                    <Preloader/>
                    <ModelLoader 
                        url                              = { url                             }
                        setTeethModelData                = { setTeethModelData               }
                        setGingivaModelData              = { setGingivaModeGeometry          }
                        setTeethModelGeometry            = { setTeethModelGeometry           }         
                        setTeethModelStepTransformation  = { setTeethModelStepTransformation }
                        setPreloaderVisible              = { setPreloaderVisible             }
                        setSmilewrapperInfo              = { setSmilewrapperInfo             }
                        onGlobalError                    = { onGlobalError                   }
                        setApplicationMode               = { setApplicationMode              }
                    />
                </>
            }

            {
                applicationMode === 'CASE_FROM_LOCAL_FILE_PARSING' &&
                <Preloader/>
            }

            {
                (applicationMode === 'CASE_FROM_LOCAL_FILE') && 
                <ZipFileLoader
                    setApplicationMode              = { setApplicationMode              }
                    setGingivaModeGeometry          = { setGingivaModeGeometry          }
                    setPreloaderVisible             = { setPreloaderVisible             }
                    setTeethModelData               = { setTeethModelData               }
                    setTeethModelStepTransformation = { setTeethModelStepTransformation }
                    setSmilewrapperInfo             = { setSmilewrapperInfo             }
                    onGlobalError                   = { onGlobalError                   }
                />
            }

            {
                ( applicationMode === 'CASE_FROM_LOCAL_FILE_DONE' || applicationMode === 'CASE_FROM_URL_DONE') && 
                <>  

                    {
                        activeTab==='TIMELINE' &&   
                            <View3dTimeline
                                gingivaModelGeometry   = { gingivaModelGeometry    }
                                gingivaModels          = { gingivaModels           }
                                teethsModels           = { teethsModels            }
                                currentView            = { currentView             } 
                                setCurrentView         = { setCurrentView          }
                                isViewClicked          = { isViewClicked           }
                                setViewClicked         = { setViewClicked          }
                                smilewrapperInfo       = { smilewrapperInfo        }
                                stepIndex              = { stepIndex               }
                                setStepIndex           = { setStepIndex            }
                                isPlayed               = { isPlayed                }
                                setIsPlayed            = { setIsPlayed             }
                                delay                  = { delay                   }
                                setDelay               = { setDelay                }
                                activeTab              = { activeTab               }
                                setActiveTab           = { setActiveTab            }
                                viewStateAccumulatorRef= { viewStateAccumulatorRef }
                                prevTabRef             = { prevTabRef              }
                                isNeedToResetViewRef   = { isNeedToResetViewRef    }
                            />
                    }


                    {
                        activeTab==='BEFORE_AFTER' &&
                            <View3dBeforeAfter
                                setCameraParameters    = { setCameraParameters     }
                                cameraPosition         = { cameraPosition          }
                                teethsModelsBefore     = { teethsModelsBefore      }
                                teethsModelsAfter      = { teethsModelsAfter       }
                                gingivaModelsBefore    = { gingivaModelsBefore     }
                                gingivaModelsAfter     = { gingivaModelsAfter      }
                                activeTab              = { activeTab               }
                                setActiveTab           = { setActiveTab            }
                                currentView            = { currentView             }
                                setCurrentView         = { setCurrentView          }
                                isViewClicked          = { isViewClicked           }
                                setViewClicked         = { setViewClicked          }
                                setStepIndex           = { setStepIndex            }
                                viewStateAccumulatorRef= { viewStateAccumulatorRef }
                                prevTabRef             = { prevTabRef              }
                                isNeedToResetViewRef   = { isNeedToResetViewRef    }
                            />
                    }

                </>
            }

            <Helper />
            
        </>
    )
}

export default JsTpviewer
